import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MockDataService {
  private jsonData: any = {};

  constructor(private http: HttpClient) {}

  loadJsonFiles(files: string[]): Observable<any[]> {
    const requests = files.map(file => this.http.get(`/assets/data/${file}`));
    return forkJoin(requests);
  }

  setJsonData(file: string, data: any): void {
    this.jsonData[file] = data;
  }

  getJsonData(file: string): any {
    return this.jsonData[file];
  }
}
