import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { placeResolver } from './core/resolvers/place.resolver';

export const appRoutes: VexRoutes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(
        (m) => m.LoginComponent
      )
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import(
        './pages/auth/forgot-password/forgot-password.component'
        ).then((m) => m.ForgotPasswordComponent)
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        loadComponent: () => import('./pages/dashboards/analytics.component').then(m => m.AnalyticsComponent),
      },
      {
        path: 'health',
        loadComponent: () => import('./pages/dashboards/health.component').then(m => m.HealthComponent),
      },
      {
        path: 'assets',
        loadComponent: () => import('./pages/assets/assets-list.component').then(m => m.AssetsListComponent),
      },
      {
        path: 'data-streams',
        loadComponent: () => import('./pages/data-streams/data-streams-list.component').then(m => m.DataStreamsListComponent),
      },
      {
        path: 'users',
        loadComponent: () => import('./pages/users/users-list.component').then(m => m.UsersListComponent),
      },
      {
        path: 'places',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/places/places-list.component').then(m => m.PlacesListComponent)
          },
          {
            path: ':id/outdoor',
            loadComponent: () => import('./pages/places/outdoor/outdoor.component').then(m => m.OutdoorComponent),
            resolve: {payload: placeResolver}
          },
          {
            path: ':id/indoor',
            loadComponent: () => import('./pages/places/indoor/indoor.component').then(m => m.IndoorComponent),
            resolve: {payload: placeResolver}
          }
        ]
      },
      {
        path: 'readers',
        loadComponent: () => import('./pages/readers/readers-list.component').then(m => m.ReadersListComponent),
      },
      {
        path: 'tags',
        loadComponent: () => import('./pages/tags/tags-list.component').then(m => m.TagsListComponent),
      },
      {
        path: 'reports',
        children: [
          {
            path: 'movement',
            loadComponent: () => import('./pages/reports/movement/movement-report.component').then(m => m.MovementReportComponent)
          },
          {
            path: 'logs',
            loadComponent: () => import('./pages/reports/logs/logs.component').then(m => m.LogsComponent)
          }
        ]
      }
    ]
  }
];
