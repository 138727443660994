import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {ITagCreate, ITagRead, ITagReadWithAsset, ITagUpdate} from '../models/tag';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {IPostResponse} from '../models/post-response';
import {IDeleteResponse} from '../models/delete-response';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root',
})
export class TagService extends BaseService {
  constructor( http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetTagsListPath = '/v1/tag';
  static readonly GetTagsFreePath = '/v1/tag/free';
  static readonly CreateTagPath = '/v1/tag';
  static readonly GetTagByIdPath = '/v1/tag/{tag_id}';
  static readonly UpdateTeamPath = '/v1/tag/{tag_id}';
  static readonly RemoveTagPath = '/v1/tag/{tag_id}';

  getList(params: {q?: string; page?: number; size?: number; order_by?: string; order?: string}, context?: HttpContext):
    Observable<IGetResponsePaginated<ITagReadWithAsset>> {
    const rb = new RequestBuilder(this.rootUrl, TagService.GetTagsListPath, 'get');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.query('q', params.q, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      if(params.order_by)
        rb.query('order_by', params.order_by, {});
      if(params.order)
        rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<ITagReadWithAsset>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<ITagReadWithAsset>>) => r.body as IGetResponsePaginated<ITagReadWithAsset>)
    );
  }

  getFree(q?: string, context?: HttpContext):
    Observable<IGetResponsePaginated<ITagRead>> {
    const rb = new RequestBuilder(this.rootUrl, TagService.GetTagsFreePath, 'get');
    rb.query('group_id', this.groupId, {});
    if (q) {
      rb.query('q', q, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<ITagRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<ITagRead>>) => r.body as IGetResponsePaginated<ITagRead>)
    );
  }


  create(params: {
    body: ITagCreate
  },
  context?: HttpContext

): Observable<IPostResponse<ITagRead>> {

    const rb = new RequestBuilder(this.rootUrl, TagService.CreateTagPath, 'post');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<ITagRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<ITagRead>>) => r.body as IPostResponse<ITagRead>)
    );
  }

  getById(params: {
    tag_id: string;
  },
  context?: HttpContext

): Observable<IGetResponse<ITagReadWithAsset>> {

    const rb = new RequestBuilder(this.rootUrl, TagService.GetTagByIdPath, 'get');
    if (params) {
      rb.path('tag_id', params.tag_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<ITagReadWithAsset>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<ITagReadWithAsset>>) => r.body as IGetResponse<ITagReadWithAsset>)
    );
  }

  update(params: {
    tag_id: string;
    body: ITagUpdate
  },
  context?: HttpContext

): Observable<IPostResponse<ITagReadWithAsset>> {

    const rb = new RequestBuilder(this.rootUrl, TagService.UpdateTeamPath, 'put');
    if (params) {
      rb.path('tag_id', params.tag_id, {});
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<ITagReadWithAsset>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<ITagReadWithAsset>>) => r.body as IPostResponse<ITagReadWithAsset>)
    );
  }


  remove(params: {
    tag_id: string;
  },
  context?: HttpContext

): Observable<IDeleteResponse<ITagRead>> {

    const rb = new RequestBuilder(this.rootUrl, TagService.RemoveTagPath, 'delete');
    if (params) {
      rb.path('tag_id', params.tag_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IDeleteResponse<ITagRead>>;
      }),
      map((r: StrictHttpResponse<IDeleteResponse<ITagRead>>) => r.body as IDeleteResponse<ITagRead>)
    );
  }
}
