import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AssetService } from '../../../../core/services/asset.service';
import { TagService } from '../../../../core/services/tag.service';
import { IAssetRead } from '../../../../core/models/assets';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ITagReadWithAsset } from '../../../../core/models/tag';
import {EmptyStateComponent} from '../../../../core/utils/empty-state'

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatRippleModule, ReactiveFormsModule, EmptyStateComponent],
  selector: 'vex-search-modal',
  template: `
    <div>
      <div class="flex items-center gap-4 px-6 py-3 border-b border-divider">
        <mat-icon svgIcon="mat:search" class="text-secondary flex-none"></mat-icon>
        <input type="text" placeholder="Search..." [formControl]="searchCtrl"
               class="text-xl font-medium bg-transparent outline-none flex-auto placeholder-secondary"/>
      </div>

      <div class="p-4">
        <div class="text-lg font-semibold text-secondary px-2 mb-2">Assets</div>

        <div class="space-y-1">
          <lll-empty-state *ngIf="!assets || assets?.length === 0"></lll-empty-state>

          <div class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
               matRipple *ngFor="let asset of assets">
            <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
              <mat-icon svgIcon="mat:business_center" class="icon-sm flex-none"></mat-icon>
            </div>
            <div class="flex-auto text-base font-medium">{{ asset?.label }}</div>
            <div class="flex-none text-xs text-secondary font-medium flex items-center gap-2">
              <div>view in place</div>
              <mat-icon svgIcon="mat:contacts" class="icon-xs flex-none"></mat-icon>
            </div>
            <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
          </div>
        </div>
      </div>

      <div class="p-4">
        <div class="text-xl font-semibold text-secondary px-2 mb-2">Tags</div>

        <div class="space-y-1">
          <lll-empty-state *ngIf="!tags || tags?.length === 0"></lll-empty-state>
          <div class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
               matRipple *ngFor="let tag of tags">
            <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
              <mat-icon svgIcon="mat:web" class="icon-sm flex-none"></mat-icon>
            </div>
            <div class="flex-auto text-base font-medium">
              <div>{{ tag?.label }}</div>
              <div class="text-secondary text-xs">/tags/tag_id</div>
            </div>
            <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [``]
})
export class SearchModalComponent implements OnInit {
  searchCtrl = new FormControl();
  isLoading = false;
  errorMsg!: string;
  tags: ITagReadWithAsset[];
  assets: IAssetRead[];

  constructor(private assetService: AssetService, private tagService: TagService) { }

  ngOnInit(): void {
    this.searchCtrl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.errorMsg = "";
          this.tags = [];
          this.isLoading = true;
        }),
        switchMap((value: string) =>
          forkJoin({
            assets: this.assetService.getList({q: value}),
            tags: this.tagService.getList({q: value})
          })
            .pipe(
              finalize(() => {
                this.isLoading = false
              }),
            )
        )
      )
      .subscribe((data: any) => {
        console.log(data);
        this.tags = data?.tags?.data?.items;
        this.assets = data?.assets.data?.items;
      });
  }
}
