import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService) {
    this.loadNavigation();
  }

  loadNavigation(): void {
/*    this._items.next([
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Analytics',
            route: '/',
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
    ]);*/
    this._items.next([
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Analytics',
            route: '/',
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Health',
            route: '/health',
            icon: 'mat:health_and_safety',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Objects',
        children: [
          {
            type: 'link',
            label: 'Assets',
            route: '/assets',
            icon: 'mat:business_center',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Readers',
            route: '/readers',
            icon: 'mat:filter_tilt_shift',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Tags',
            route: '/tags',
            icon: 'mat:label',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Location',
        children: [
          {
            type: 'link',
            label: 'Places',
            route: '/places',
            icon: 'mat:place',
            routerLinkActiveOptions: { exact: true }
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Reports',
        children: [
          {
            type: 'link',
            label: 'Movement',
            route: '/reports/movement',
            icon: 'mat:local_shipping',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Logs',
            route: '/reports/logs',
            icon: 'mat:table_view',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Settings',
        children: [
          {
            type: 'link',
            label: 'Data streams',
            route: '/data-streams',
            icon: 'mat:data_usage',
            routerLinkActiveOptions: { exact: true }
          },
        ]
      },
    ]);
  }
}
